import axios from 'axios'
import { extractError } from '../utils/errorHandler'

export const getById = async (id, include = false) => {
  try {
    let includeString = include !== false ? '?include=process,material' : ''
    const { data: workOrder } = await axios.get(`workOrder/${id}${includeString}`)
    return workOrder
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getWorkOrderErrors = async (id, producedGoodQuantity, producedBadQuantity) => {
  try {
    const { data: workOrder } = await axios.get(
      `workOrder/can-end-execution/${id}/${producedGoodQuantity ?? 0}/${producedBadQuantity ?? 0}`
    )
    return workOrder
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getMaterialDetails = async (id) => {
  try {
    const { data: workOrderMaterialDetails } = await axios.get(`workOrder/${id}/materialsDetails`)
    return workOrderMaterialDetails
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getLastWorkOrder = async (data) => {
  try {
    const { data: productionTracking } = await axios.get('workOrder/last', data)
    return productionTracking
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const create = async (data) => {
  try {
    const { data: productionTracking } = await axios.post(
      'workOrder',
      data.map((d) => ({
        ...d,
        time: d.time ? d.time : null,
        workStation:
          d.workStation && d.workStation.filter((c) => c).length > 0 ? d.workStation : [],
      }))
    )
    return productionTracking
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const remove = async (id) => {
  try {
    const { data: productionTracking } = await axios.delete('workOrder/' + id)
    return productionTracking
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const removeAll = async (id) => {
  try {
    const { data: productionTracking } = await axios.delete('workOrder/' + id + '/all')
    return productionTracking
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getRealLaborTime = async (id) => {
  try {
    const { data: productionTracking } = await axios.get(`workOrder/${id}/realLaborTime`)
    return productionTracking
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getAllByCode = async (code) => {
  try {
    const { data: workOrder } = await axios.get(`workOrder/allByCode?code=${code}`)
    return workOrder
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const getByCode = async (code, operation) => {
  try {
    const { data: workOrder } = await axios.get(`workOrder?code=${code}&operation=${operation}`)
    return workOrder
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const reset = async (workOrder) => {
  try {
    const { data: updatedWorkOrder } = await axios.post(
      `workOrder/${workOrder.id}/reset`,
      workOrder
    )
    return updatedWorkOrder
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const updateWorkOrderNote = async (workOrder) => {
  try {
    if (workOrder.workNote?.workOrderNoteDto)
      return await updateWorkOrderNoteOrProperties(workOrder.workNote)

    const { data: updatedWorkOrder } = await axios.patch('workOrder/updateWorkOrderNote', workOrder)
    return updatedWorkOrder
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const updateWorkOrderNoteOrProperties = async (workOrder) => {
  try {
    const { data: updatedWorkOrder } = await axios.patch(
      'workOrder/updateWorkOrderNoteOrProperties',
      workOrder
    )
    return updatedWorkOrder
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}

export const updateProducedQuantities = async (producedQuantities) => {
  try {
    await axios.patch('workOrder/updateProducedQuantities', producedQuantities)
  } catch (err) {
    const apiError = extractError(err)
    throw new Error(apiError.message || apiError.Message)
  }
}
